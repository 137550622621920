<template>
    <div>
        <b-button-toolbar class="mb-3">
            <b-button-group class="ml-auto">
                <Button to="list">
                    <b-icon icon="list" />
                    Список
                </Button>
            </b-button-group>
        </b-button-toolbar>
        <b-overlay :show="request">
            <b-form
                autocomplete="off"
                @submit="submit"
            >
                <b-form-group
                    label="Название"
                    label-for="name"
                >
                    <b-form-input
                        id="name"
                        v-model="form.name"
                        type="text"
                        placeholder="Введите название"
                        required
                        :state="errors.name ? false : null"
                    />
                    <b-form-invalid-feedback>
                        <ul>
                            <li
                                v-for="error in errors.name"
                                :key="error"
                            >
                                {{ error }}
                            </li>
                        </ul>
                    </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                    label="Дней с"
                    label-for="startDays"
                >
                    <b-form-input
                        id="startDays"
                        v-model="form.startDays"
                        type="text"
                        placeholder="Введите кол-во дней"
                        required
                        :state="errors.startDays ? false : null"
                        autocomplete="off"
                        :number="true"
                    />
                    <b-form-invalid-feedback>
                        <ul>
                            <li
                                v-for="error in errors.startDays"
                                :key="error"
                            >
                                {{ error }}
                            </li>
                        </ul>
                    </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                    label="Дней по"
                    label-for="endDays"
                >
                    <b-form-input
                        id="endDays"
                        v-model="form.endDays"
                        type="text"
                        placeholder="Введите кол-во дней"
                        required
                        :state="errors.endDays ? false : null"
                        autocomplete="off"
                        :number="true"
                    />
                    <b-form-invalid-feedback>
                        <ul>
                            <li
                                v-for="error in errors.endDays"
                                :key="error"
                            >
                                {{ error }}
                            </li>
                        </ul>
                    </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                    label="День смены коллектора"
                    label-for="shiftDays"
                >
                    <b-form-input
                        id="shiftDays"
                        v-model="form.shiftDays"
                        type="text"
                        placeholder="Введите кол-во дней"
                        required
                        :state="errors.shiftDays ? false : null"
                        autocomplete="off"
                        :number="true"
                    />
                    <b-form-invalid-feedback>
                        <ul>
                            <li
                                v-for="error in errors.shiftDays"
                                :key="error"
                            >
                                {{ error }}
                            </li>
                        </ul>
                    </b-form-invalid-feedback>
                </b-form-group>

                <Button
                    type="submit"
                    variant="primary"
                >
                    Сохранить
                </Button>
            </b-form>
        </b-overlay>
    </div>
</template>

<script>
export default {
    data() {
        return {
            form: {
                name: null,
                startDays: null,
                endDays: null,
                shiftDays: null,
            },
            errors: {},
            request: false,
        }
    },
    methods: {
        submit(event) {
            event.preventDefault();

            if (this.request) {
                return;
            }

            this.request = true;

            this
                .jsonRpc("admin.stages.add", {...this.form})
                .then(async (result) => {
                    this.$alertify.success("Запись добавлена");
                    this.$router.push({path: `${result.id}`});
                })
                .catch(async ({error}) => {
                    if (this.$formValidation.isValidationRpcResponse(error)) {
                        this.errors = this.$formValidation.parse(error.data);
                    }
                })
                .finally(async () => {
                    this.request = false;
                });

        },
    },
}
</script>
